import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import PageHeader from "../components/PageHeader/PageHeader";
import InfoBox from "../components/InfoBox";
import { PageContainer, PageContent, Section } from "../styles";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Footer from "../components/Footer";
import styled from "styled-components";

export default function About() {
  const { image, image1, image2 } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "grace.jpg" }) {
        sharp: childImageSharp {
          fixed(width: 300, height: 500, quality: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image1: file(relativePath: { eq: "accredited-logo.jpg" }) {
        sharp: childImageSharp {
          fixed(width: 240, height: 170, quality: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image2: file(relativePath: { eq: "cosca.jpg" }) {
        sharp: childImageSharp {
          fixed(width: 240, height: 184, quality: 85) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>About - Peace By Piece CBT</title>
        <meta
          name="description"
          content="I like to work with you as an individual, not with you as a label,
            so that you are looking at yourself as a unique person - not a
            diagnosis."
        />
      </Helmet>
      <PageContainer>
        <PageHeader title="Grace Lewis" description="CBT Therapist" />
        <PageContent>

      <div className="container">
        <div className="row">
            <Section>
              <InfoBox>
                <div>
                <Grace style={{float: "left"}} src={image.sharp.fixed.src} alt="Grace Lewis - CBT Therapy" />
                  <p>
                      Hi, I'm Grace. I have worked for 30+ years within the National Health Service as a qualified
                      nurse, and in the last 10 years have trained and qualified as a Cognitive Behavioural Therapist.
                      Dealing with the challenges that present at the time, but also working with individuals to work on
                      strategies to help maintain their wellness and prevent setbacks.
                    </p>
                    <p>
                      The preventative could be looked at as coaching, but to assist people on how to improve and
                      maintain their mental wellness, because we all get stuck from time to time. I currently volunteer
                      as a psychotherapist within a cancer support group, where I come alongside individuals who are at
                      present dealing with chronic acute illness, life altering and limiting diagnosis for the
                      individual and their familiies.
                    </p>
                    <p>
                      I like to work with you as an individual, not with you as a label, so that you are looking at
                      yourself as a unique person - not a diagnosis.
                    </p>
                    <ImageFooter>
                      <img alt="Professional Standards Authority" src={image1.sharp.fixed.src} />
                      <img alt="COSCA - Counselling &amp; Psychotherapy Scotland" src={image2.sharp.fixed.src} />
                    </ImageFooter>
                </div>
              </InfoBox>
            </Section>
        </div>
      </div>
      <div style={{ background: "rgb(30, 36, 54)" }}>
        <Footer contactForm={true} />
      </div>
      </PageContent>
      </PageContainer>
    </Layout>
  );
}

const ImageFooter = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`

const Grace = styled.img`
  width: 100%;
  max-width: 300px;
  min-width: 180px;
  height: auto;
  margin: 2rem;
`

const AboutSection = styled.div`
display: flex;
flex-direction: column;
align-items: center;
}
`;

const TextSection = styled.div`
  @media (min-width: 900px) {
    float: right;
  }
`;

const ImageSection = styled.div`
  display: flex;
  margin: 0 0 1rem;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 0 0 8rem;
  @media (max-width: 1024px) {
    padding: 0;
    flex-direction: row;
    div {
      margin: 1rem 1rem 0;
    }
  }
`;
